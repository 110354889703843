import React from "react"
import MainLayout from "../../layouts"
import { Col, Container, Row } from "react-bootstrap"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ContactHeading from "../../components/ContactHeading"
import ServiceCards from "../../ServiceCards"

export const pageQuery = graphql`
  query {
    rosemontImage: file(relativePath: { eq: "rosemont.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithRekeyingLockImage: file(
      relativePath: { eq: "locksmith-rekeying-lock.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leoInCarImage: file(relativePath: { eq: "leo-in-car.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithVanInCityImage: file(
      relativePath: { eq: "locksmith-van-in-city.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithCarInNeighborhoodImage: file(
      relativePath: { eq: "locksmith-car-in-neighborhood.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

function RosemontPage({ data }) {
  return (
    <MainLayout
      title="Licensed & Insured Locksmith - Rosemont, CA - 24/7 Service"
      description="For all of your home, car, & business lock & key solutions, Swift Locksmith has you covered! Certified techs., fast response & competitive rates. 916-995-0135"
      offset
    >
      <ContactHeading background={data.rosemontImage.childImageSharp.fluid}>
        <h1 className="text-center">Locksmith Rosemont</h1>
        <h2 className="text-center font-weight-light">
          24 Hour Rosemont Locksmith
        </h2>
      </ContactHeading>
      <section>
        <ServiceCards />
      </section>
      <Container as="section">
        <h2>Welcome to Swift Locksmith in Rosemont, CA</h2>
        <p className="indent mb-0">
          Locally owned and operated, Swift Locksmith is a complete locksmith
          company in Rosemont, CA. We cover a wide range of services that
          includes a door lock repair, keyless entry, access control, master key
          systems, to higher security commercial locks and 24/7 emergency help.
          Our skilled team of locksmiths are all licensed, insured, professional
          and friendly. Our goal is keeping you secure and safe and to solve all
          of your locksmith needs. From the time you give us a call for
          assistance, you're becoming a valued client and we are going to
          provide you the very best service and make sure you're always fully
          satisfied with our service. We make sure to provide special rates and
          deals for your upcoming experience with our business because our
          future with you is important to us.
        </p>
      </Container>
      <Container as="section">
        <Row>
          <Col xs={12} md={4}>
            <Row className="flex-column mb-3 align-items-center">
              <Col md={{ order: 2 }} style={{ maxWidth: 415 }}>
                <h2 className="text-center">Residential</h2>
                <ul className="mb-md-0">
                  <li>24/7 Emergency Lockout</li>
                  <li>Lock Repair & Maintenance</li>
                  <li>Door Knob Lock Installation</li>
                  <li>Duplicate House Keys</li>
                  <li>High Security Locks</li>
                  <li>Master Key Systems</li>
                  <li>On-Site Key Extraction</li>
                  <li>Whole House Re-key</li>
                </ul>
              </Col>
              <Col className="col-sm-pad mb-md-3">
                <Img
                  fluid={data.locksmithRekeyingLockImage.childImageSharp.fluid}
                  alt="locksmith-rekeying-lock"
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={4}>
            <Row className="flex-column mb-3 align-items-center">
              <Col md={{ order: 2 }} style={{ maxWidth: 415 }}>
                <h2 className="text-center">Automotive</h2>
                <ul className="mb-md-0">
                  <li>24/7 Lock Out Services</li>
                  <li>Broken Key Extraction</li>
                  <li>Ignition Rekey</li>
                  <li>Laser Cut Keys</li>
                  <li>Chip Keys Cut Services</li>
                  <li>Remote Programming</li>
                  <li>Lost Car Key Replacement</li>
                  <li>Replace Keyless Entry Remotes</li>
                </ul>
              </Col>
              <Col className="col-sm-pad mb-md-3">
                <Img
                  fluid={data.leoInCarImage.childImageSharp.fluid}
                  alt="leo-in-car"
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={4}>
            <Row className="flex-column mb-3 align-items-center">
              <Col md={{ order: 2 }} style={{ maxWidth: 415 }}>
                <h2 className="text-center">Commercial</h2>
                <ul className="mb-md-0">
                  <li>24/7 Emergency Lockout</li>
                  <li>Business Re-key</li>
                  <li>Locks & Exit Device Repair</li>
                  <li>Master Key system Installation</li>
                  <li>Keyless Access System</li>
                  <li>Deadbolts, High Security Deadbolts</li>
                  <li>On-site Key Duplication</li>
                  <li>Aluminum Door Lock Installation</li>
                </ul>
              </Col>
              <Col className="col-sm-pad mb-md-3">
                <Img
                  fluid={data.locksmithVanInCityImage.childImageSharp.fluid}
                  alt="locksmith-van-in-city"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container as="section" className="pb-0 pb-sm-5 mb-md-5">
        <h2>Why Choose Swift Locksmith in Rosemont, CA</h2>
        <Row>
          <Col xs={12} md={6} lg={7} xl={8}>
            <p className="indent">
              We are proud to provide you with the best customer care,
              professional service and affordable pricing. Our customers always
              come first and we make sure to personalize each service to meet
              the needs of each customer. Regardless of whether you're wanting
              to re-key your house, repair your vehicle lock, or maybe you end
              up locked out of your office, we are able to assist you quickly
              and efficiently. Our team of specialists are qualified to deal
              with various locksmith scenarios and therefore are professional
              and friendly which means you are able to trust our staff to
              resolve any of your locksmith problems.
            </p>
            <p className="indent mb-md-0">
              As a mobile locksmith company, we always come to you and bring the
              right tools and materials to your location. It's our job to
              provide excellent locksmith products for each Rosemont resident to
              have a sense of security for themselves, their home, vehicle and
              business. Regardless of whether you are looking for emergency
              services or not, we're always available and ready to help, so
              contact us today and find out how we can help or improve your
              locksmith security needs.
            </p>
          </Col>
          <Col xs={12} md={6} lg={5} xl={4} className="col-sm-pad">
            <Img
              fluid={data.locksmithCarInNeighborhoodImage.childImageSharp.fluid}
              alt="locksmith-car-in-neighborhood"
            />
          </Col>
        </Row>
      </Container>
    </MainLayout>
  )
}

export default RosemontPage
